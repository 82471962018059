import React from 'react';
import Dictionary from "./Dictionary.js";

function Square(props) {
  return (
    <div style={squareStyle}>
    {props.value}</div>
  );
}

// positioning of things 
// the whole page
const parentStyle = {
  width: '100%',
  display: 'flex',
  color:'#0877D1',
  background: '#E6EFF7',
  fontFamily: 'Helvetica'
}

// the score + remaining word count 
const col1Style = {
  //background: 'black',
  margin: 'auto',
  width: '25%',
  order: '-1',
  height: 300
}

// the board + reshuffle
const col2Style = {
  width: 'max-content',
  margin: 'auto',
  whiteSpace: 'pre-line',
  marginTop: -20,
}

// the list of words + text box
const col3Style = {
  width: '25%',
  height: 300,
  margin: 'auto',
}

// styling of things

// score count
const scoreStyle = {
  fontSize: 20,
  fontFamily: 'sans-serif',
  fontWeight: 'bold',
  //border: 'solid',
  //borderRadius: 20,
  width: '75%',
  padding: 10,
  color: '#0877D1',
  margin: 'auto'
}

// remaining word count
const chartStyle = {
  color: '#0877D1',
  padding: 10,
  margin: 'auto',
  marginTop: '20%',
  width: '75%',
  height: '100%',
  //border: 'solid',
  //borderRadius: 20,
  fontSize: 20,
  fontWeight: 'bold',
  whiteSpace: 'pre',
}

// just the display of length + their # of words
const wordcountStyle = {
  margin: 'auto',
  padding: 10,
  display: 'table'
}

// boggle cubies 
const squareStyle = {
  width: 65,
  display: 'inline-block',
  border: 'solid',
  padding: 2,
  margin: 2,
  borderRadius: 6,
  color:'#0877D1',
  fontWeight: 'bold',
  fontSize: '50px',
  background: '#FAF7F8',
  boxShadow: "1px 1px 1px 1px inset #9E9E9E",
  //margin: 'auto'
}

// board style
const boardStyle = {
  background: '#0877D1',
  display: 'block',
  border: 'solid',
  borderRadius: 6,
  borderColor: '#0877D1',
  borderWidth: 4,
}

// reshuffle button
const reshuffleStyle = {
  background:'#0877D1',
  color: '#FAF7F8',
  padding: 10,
  margin: 10,
  border: 'solid',
  borderColor: '#0877D1',
  borderRadius: 20,
  fontSize: 20,
  fontWeight: 'bold',
  textTransform: 'uppercase',
}

// list of all words entered thus far
const enteredWordsStyle = {
  overflow: 'auto',
  //border: 'solid',
  //borderRadius: 20,
  width: '75%',
  //height: '100%',
  //borderColor: '#004efa',
  color: '#0877D1',
  fontSize: 20,
  fontWeight:'bold'
}

// text that appears when you enter an answer
const answerStyle = {
  padding: 5,
  height: 20,
  fontSize: 20,
  fontWeight: 'bold'
}

class Board extends React.Component {
  constructor(props) {
    super(props);

    let rows = [];
    for (let i = 0; i < 6; i++) {
      rows.push([]);
      for (let j = 0; j < 6; j++) {
        rows[rows.length - 1].push([i,j]);
      }
    }

    this.state = {
      rows: rows,
    };
  }

  renderSquare(i, j) {
    return (
      <Square
        value={this.props.board[""+i+j]}
        key={""+i+j}
      />
    );
  }

  render() {
    return (
      <div style={boardStyle}>
        {this.state.rows.map(
          (row, index) =>
            <div className="board-row" key={index}>
              {row.map(
                index => 
                  this.renderSquare(index[0], index[1])
              )}
            </div>)}
      </div>
    );
  }
}

class Game extends React.Component {
  constructor(props) {
    super(props);

    let cubes = [
        ['A','A','A','F','R','S'],
        ['A','A','E','E','E','E'],
        ['A','A','E','E','O','O'],
        ['A','A','F','I','R','S'],
        ['A','B','D','E','I','O'],
        ['A','D','E','N','N','N'],
        ['A','E','E','E','E','M'],
        ['A','E','E','G','M','U'],
        ['A','E','G','M','N','N'],
        ['A','E','I','L','M','N'],
        ['A','E','I','N','O','U'],
        ['A','F','I','R','S','Y'],
        ['An','Er','He','In','Qu','Th'],
        ['B','B','J','K','X','Z'],
        ['C','C','E','N','S','T'],
        ['C','D','D','L','N','N'],
        ['C','E','I','I','T','T'],
        ['C','E','I','P','S','T'],
        ['C','F','G','N','U','Y'],
        ['D','D','H','N','O','T'],
        ['D','H','H','L','O','R'],
        ['D','H','H','N','O','W'],
        ['D','H','L','N','O','R'],
        ['E','H','I','L','R','S'],
        ['E','I','I','L','S','T'],
        ['E','I','L','P','S','T'],
        ['E','I','O','R','S','E'],
        ['E','M','T','T','T','O'],
        ['E','N','S','S','S','U'],
        ['G','O','R','R','V','W'],
        ['H','I','R','S','T','V'],
        ['H','O','P','R','S','T'],
        ['I','P','R','S','Y','Y'],
        ['J','K','Qu','W','X','Z'],
        ['N','O','O','T','U','W'],
        ['O','O','O','T','T','U']
      ];

    let root_dictionary = Dictionary;
    let rng = new SeededRandom(42);

    let board = generateBoard(cubes, root_dictionary, 50, rng);

    let words = findDaWordies(board, root_dictionary);

    let wordsByLength = {};
    for (let i = 0; i < words.length; i++) {
      let word = words[i];
      if (!(word.length in wordsByLength)) {
        wordsByLength[word.length] = [];
      }
      wordsByLength[word.length].push(word);
    }

    this.state = {
      board: board,
      cubes: cubes,
      dictionary: root_dictionary,
      words: words,
      wordsByLength: wordsByLength,
      foundWords: [],
      score: 0,
      seed: 42,
      rng: rng,
    };
  }

  reshuffle() {
    let board = generateBoard(this.state.cubes, this.state.dictionary, 50, this.state.rng);
    let words = findDaWordies(board, this.state.dictionary);

    let wordsByLength = {};
    for (let i = 0; i < words.length; i++) {
      let word = words[i];
      if (!(word.length in wordsByLength)) {
        wordsByLength[word.length] = [];
      }
      wordsByLength[word.length].push(word);
    }
    this.setState({
      board: board,
      words: words,
      wordsByLength: wordsByLength,
      foundWords: [],
      text: "",
      displayTip: null,
      score: 0,
    });
  }

  updateInput(event) {
    let word = event.target.value;
    var displayTip = null;
    var foundWords = this.state.foundWords;
    var wordsByLength = this.state.wordsByLength;

    let score = this.state.score;
    
    if (word.includes('\n')) {
      word = word.toLowerCase().trim();
      if (!(this.state.words.includes(word))) {
        displayTip = word + " is not a valid word";
      } else if (foundWords.includes(word)) {
        displayTip = "You already added " + word;
      } else {
        foundWords.push(word);
        score += scoreForWord(word);
        wordsByLength[word.length].splice(wordsByLength[word.length].indexOf(word), 1);
        displayTip = "Added " + word ;
      }
      this.setState({text: "", displayTip: displayTip, foundWords: foundWords, wordsByLength: wordsByLength, score: score});
    } else {
      displayTip = null;
      this.setState({text: word, displayTip: displayTip});
    }
  }

  updateSeed(event) {
    let seed = parseInt(event.target.value);
    let rng = new SeededRandom(seed);
    this.setState({seed: seed, rng: rng})
  }

  render() {
    let lengths = Object.keys(this.state.wordsByLength);
    lengths.sort(function(a, b){return a - b});
    var wordCount = "";
    for (let i = 0; i < lengths.length; i++) {
      wordCount += lengths[i] + ": " + this.state.wordsByLength[lengths[i]].length;
      if (i !== lengths.length - 1) {
        wordCount += "\n";
      }
    }

    return (
        <div className="game" style={parentStyle}>

        <div style={col2Style} className="game-board"><h1>{"Max + Kendra's\nBoggle Board"}</h1>
          <Board
            board={this.state.board}
          />
        <div style={answerStyle}>{this.state.displayTip}</div>

        <button style={reshuffleStyle} onClick={() => this.reshuffle()}>
          Reshuffle
        </button>
        </div>
    
        <div style={col1Style}>
          <div style={scoreStyle}>Score: {this.state.score}</div>

          <div style={chartStyle}>
            Remaining Words by Length 
            <div style={wordcountStyle}>
            {wordCount}
            </div>
          </div> 
        </div>


        <div style={col3Style}>
          <form>
            <textarea value={this.state.text} onChange={event => this.updateInput(event)} placeholder="Enter your word"/>
            <textarea value={this.state.seed} onChange={event => this.updateSeed(event)} placeholder="Random Seed"/>
          </form>
          <div style={enteredWordsStyle}>
          {this.state.foundWords.map(
            (word, index) => {
              if (this.state.foundWords.length === (index + 1)) { 
                return word;
              } else {
                return word + ", ";
              }
            }
          )}
          </div>
        </div>
      </div>
    );
  }
}

function SeededRandom(seed) {
    this.seed = seed % 2147483647;
    if (this.seed <= 0) this.seed += 2147483646;
}

SeededRandom.prototype.next = function () {
    return this.seed = this.seed * 16807 % 2147483647;
};

SeededRandom.prototype.nextDouble = function () {
    return (this.next() - 1) / 2147483646;
};

function generateBoard(cubes, dictionary, num_boards, rng) {
  var best_board = null;
  var best_score = 0;

  for (let k = 0; k < num_boards; k++) {
    let board = {};

    let cubeOrder = [];
    for (let i = 0; i < 36; i++) {
      cubeOrder.push(i);
    }
    for (let i = cubeOrder.length - 1; i > 0; i--) {
      let j = Math.floor(rng.nextDouble() * (i + 1));
      let temp = cubeOrder[i];
      cubeOrder[i] = cubeOrder[j];
      cubeOrder[j] = temp;
    }

    let index = 0;
    for (let i = 0; i < 6; i++) {
      for (let j = 0; j < 6; j++) {
        let choice = Math.floor(rng.nextDouble() * 6);
        let letter = cubes[cubeOrder[index]][choice];
        board[""+i+j] = letter;
        index += 1;
      }
    }

    let words = findDaWordies(board, dictionary);
    var score = 0
    for (let i = 0; i < words.length; i++) {
      score += scoreForWord(words[i]);
    }

    if (score > best_score) {
      best_score = score;
      best_board = board;
    }
  }
  
  return best_board;
}

function recursiveSearch(board, valid, cur, curi, curj, dictionary, words, word) {
  valid[cur] = false;
  let cur_char = board[cur].toLowerCase()

  if (cur_char.length > 1) {
    dictionary = dictionary[cur_char[0]];
    word += cur_char[0];
    cur_char = cur_char[1];
  }

  dictionary = dictionary[cur_char];
  word += cur_char;

  if ('valid' in dictionary && !words.includes(word)) {
    words.push(word);
  }

  for (let ii = -1; ii < 2; ii++) {
    let i = ii + curi;
    if (i < 0 || i > 5) {
      continue;
    }
    for (let jj = -1; jj < 2; jj++) {
      let j = jj + curj;
      if (j < 0 || j > 5) {
        continue;
      }
      let lookup = ''+i+j;
      if (valid[lookup] === false) {
        continue;
      }
      let char = board[lookup].toLowerCase();
      if (char.length > 1) {
        if (char[0] in dictionary && char[1] in dictionary[char[0]]) {
          recursiveSearch(board, valid, ''+i+j, i, j, dictionary, words, word);
        }
      } else if (char in dictionary) {
        recursiveSearch(board, valid, ''+i+j, i, j, dictionary, words, word);
      }
    }
  }
  valid[cur] = true;
}

function scoreForWord(word) {
  if (word.length === 5) {
    return 2;
  } else if (word.length === 6) {
    return 3;
  } else if (word.length === 7) {
    return 5;
  } else if (word.length === 8) {
    return 11;
  } else if (word.length === 9) {
    return 15;
  } else if (word.length === 10) {
    return 20;
  } else if (word.length === 11) {
    return 30;
  } else if (word.length === 12) {
    return 40;
  } else if (word.length === 13) {
    return 50;
  } else {
    return 100;
  }
}

function findDaWordies(board, dictionary) {
  let words = [];
  let valid = {};
  for (let i = 0; i < 6; i++) {
    for (let j = 0; j < 6; j++) {
      valid[''+i+j] = true;
    }
  }
  for (let i = 0; i < 6; i++) {
    for (let j = 0; j < 6; j++) {
      recursiveSearch(board, valid, ''+i+j, i, j, dictionary, words, '');
    }
  }
  return words;
}

export default Game;
